import React from 'react';
import vabest2023 from '../assets/images/bptw2023purple.jpg';
import inc5000 from '../assets/images/inc5000.png';
import rev from '../assets/images/revpurple.png';
import wbj2023 from '../assets/images/wbj2023purple.png';
import wbjfgc from '../assets/images/wbjfgc.png';

const Footer = props => (
  <footer id="footer">
    <div className="inner">
      <div className="grid-wrapper">
        <section className="col-6">
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/blackcapeio"
                className="icon alt fa-twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/blackcape.io/"
                className="icon alt fa-facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/blackcape.io/?hl=en"
                className="icon alt fa-instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.github.com/black-cape"
                className="icon alt fa-github"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/black-cape"
                className="icon alt fa-linkedin"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="label">LinkedIn</span>
              </a>
            </li>
          </ul>
          <ul className="copyright">
            <li>&copy; {`${[...new Set([2019, new Date().getFullYear()])].join(' - ')}`}</li>
            <li>Black Cape, Inc.</li>
            <li>
              <a href="https://app.termly.io/document/privacy-policy/8f75ef0c-7835-46cd-a371-6306a57954a3">Privacy</a>
            </li>
            <li>
              <a href="https://mrf.healthcarebluebook.com/TrustmarkHB">Transparency in Coverage</a>
            </li>
          </ul>
        </section>
        <section className="col-3 images">
          <img alt="Washington Business Journal FGC 2022" src={wbjfgc} />
          <img alt="Arlington REV 2022" src={rev} />
          <img alt="VA Best Places to Work 2023" src={vabest2023} />
        </section>
        <section className="col-3 images">
          <img alt="Washington Business Journal 2023" src={wbj2023} />
          <img alt="Inc. 5000" src={inc5000} />
        </section>
      </div>
    </div>
  </footer>
);

export default Footer;
