import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const Menu = props => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        <li>
          <Link onClick={props.onToggleMenu} to="/">
            Home
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/#focus">
            Focus Areas
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/#products">
            Products
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/company">
            Company
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/news">
            News
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/blog">
            Blog
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/careers">
            Careers
          </Link>
        </li>
      </ul>
      <ul className="actions vertical">
        <li>
          <a
            href="https://blackcape.applytojob.com/apply/"
            className="button special fit"
            target="_blank"
            rel="noreferrer"
          >
            Apply
          </a>
        </li>
      </ul>
    </div>
    <a className="close" onClick={props.onToggleMenu}>
      Close
    </a>
  </nav>
);

Menu.propTypes = {
  onToggleMenu: PropTypes.func
};

export default Menu;
