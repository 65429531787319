import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import logo from '../assets/images/bclogo.svg';

const Header = props => (
  <header id="header" className="alt">
    <Link to="/" className="logo" style={{margin: '0px', padding: '0px', position: 'relative'}}>
      <div className="triangle header-triangle" />
      <img className="header-logo" alt="Header" src={logo} />
    </Link>
    <nav>
      <a className="menu-link" onClick={props.onToggleMenu}>
        Menu
      </a>
    </nav>
  </header>
);

Header.propTypes = {
  onToggleMenu: PropTypes.func
};

export default Header;
